import {
  Grid,
  IconHands,
  IconMeineKundenwelt,
  Spacer,
  Text,
} from 'dss-ui-library';
import { AddressRow } from '../../Elements/AddressRow';
import { useOrderContext } from '../../../OrderContext';
import { IconTextSection } from './IconTextSection';
import { buildPlanOverviewUrl } from '../../../../utils/plan';
import { ProductModifiers } from '../../../../interfaces/product';
import { useAvailabilityContext } from '../../Context';
import { KUNDENWELT_ACTIONCODE_URL } from '../../../../constants/urls';
import { isNC, ACTION_CODES_MKW, Media } from '@ncs-frontend-monorepo/utils';
import styles from './NetTvRedirect.module.scss';

interface NetTVRedirectProps {
  resetAddress: () => void;
}

export const NetTvRedirect: React.FC<NetTVRedirectProps> = ({
  resetAddress,
}) => {
  const { availablePortfolio } = useAvailabilityContext();
  const { address } = useOrderContext();

  const internetTvRedirect = buildPlanOverviewUrl({
    portfolio: availablePortfolio,
    isYoung: false,
    productModifier: ProductModifiers.withoutPhone,
  });
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Text appearance="t2" color="blue" block className={styles.title}>
            Perfekt. Dein Wunschtarif ist verfügbar!
          </Text>
          <Spacer b={1} block />
          <AddressRow address={address} onChangeAddressClick={resetAddress} />
          <Spacer b={3}>
            <Text appearance="t3">
              Egal ob neuer oder treuer Kunde - NetTV über Internet können alle
              erleben.
            </Text>
          </Spacer>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column xs={12} m={6}>
          <IconTextSection
            icon={<IconMeineKundenwelt width={40} height={40} color="blue" />}
            title={`Du bist bereits ${
              isNC() ? 'NetCologne' : 'NetAachen'
            } Kunde?`}
            text="Als treuer Kunde kannst du NetTV über Internet ganz einfach zu deinem bestehenden Internet-Tarif hinzubuchen. Logge dich in deine Kundenwelt ein, um NetTV über Internet zu buchen."
            link={{
              title: 'Jetzt bestellen',
              url: KUNDENWELT_ACTIONCODE_URL + ACTION_CODES_MKW.TV_COMBI,
              e2e: 'to-internet-tv',
            }}
          />
        </Grid.Column>
        <Media between={['xs', 'm']}>
          <Spacer t={1} />
        </Media>
        <Grid.Column xs={12} m={6}>
          <IconTextSection
            icon={<IconHands width={40} height={40} color="blue" />}
            title={`Du bist noch kein ${
              isNC() ? 'NetCologne' : 'NetAachen'
            } Kunde?`}
            text={`Um NetTV über Internet buchen zu können, benötigst du einen Internet-Tarif von ${
              isNC() ? 'NetCologne' : 'NetAachen'
            }. Buche dir also direkt dein passendes Kombipaket aus Internet und TV.`}
            link={{
              title: 'Zu den Kombipaketen',
              url: internetTvRedirect,
              e2e: 'to-nettv-internet',
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
