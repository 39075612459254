export const FIBER_PLANS = `${process.env.NEXT_PUBLIC_SITE_PREFIX}/glasfaser-tarife/`;
export const CAMPAIGN_PLANS = `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/kampagne/`;
export const EXPANSION_BUILDERS = `${process.env.NEXT_PUBLIC_SITE_PREFIX}/ausbau/bauherren/`;

export const PRODUCT_OVERVIEW_PATHS = {
  TV: '/fernsehen/nettv-tv-internet',
  TV_CABLE_OPTIONS: '/fernsehen/nettv-tv-kabel/zusatzoptionen/hd-pakete',
};

export const NET_TV_URL = `${process.env.NEXT_PUBLIC_SITE_PREFIX || ''}${
  PRODUCT_OVERVIEW_PATHS.TV
}`;

export const TV_STANDALONE_CLASSIC = `${process.env.NEXT_PUBLIC_SITE_PREFIX}/fernsehen/nettv-tv-kabel/angebot-dsl/`;
export const TV_STANDALONE_FIBER = `${process.env.NEXT_PUBLIC_SITE_PREFIX}/fernsehen/nettv-tv-kabel/angebot-glasfaser/`;

export const PRIVACY_POLICY_URL = '/datenschutz';

export const PHONE_NC = '02212222800';
export const PHONE_NA = '08002222333';

export const ORDER_NETSPEED_URL = `${process.env.NEXT_PUBLIC_SITE_PREFIX}/bestellung/persoenliche-daten/`;
export const ORDER_TV_URL = `${process.env.NEXT_PUBLIC_SITE_PREFIX}/bestellung/tv/persoenliche-daten/`;
export const ORDER_FTTH_FACTORY_URL = `${process.env.NEXT_PUBLIC_SITE_PREFIX}/ftth/bestellung/`;

export const CONTACT_URL = `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kontakt`;
export const BUILDINGOWNER_CONTACT_URL = `${process.env.NEXT_PUBLIC_SITE_PREFIX}/ausbau/bauherren/kontaktformular`;

export const EXPANSION_URL_NC = 'https://www.netcologne.de/ausbaugebiete';
export const EXPANSION_URL_NA = 'https://www.netaachen.de/weisseflecken';

export const KUNDENWELT_URL = 'https://meinekundenwelt.netcologne.de';
export const KUNDENWELT_ACTIONCODE_URL = KUNDENWELT_URL + '/e/';

export const LOGIN_URL =
  'https://sso.netcologne.de/cas/login?service=https://meinekundenwelt.netcologne.de/';
export const LOGIN_URL_NA =
  'https://sso.netcologne.de/cas/login?service=https://meinekundenwelt.netcologne.de/';

export const EXPANSION_CHECK_LANDINGPAGE_GK_NC =
  'https://www.netcologne.de/geschaeftskunden/internet/glasfaser-tarife-landingpage/';
export const EXPANSION_CHECK_LANDINGPAGE_GK_NA =
  'https://www.netaachen.de/geschaeftskunden/kontakt/kontaktformular/';
