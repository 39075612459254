export const toE2E = (value: string) =>
  value?.toLowerCase().replace(/\s{1}/g, '-');

export const toCamelCase = (value: string): string =>
  String(value)
    .toLowerCase()
    .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));

export const getElementId = (id: number): string => `elementId-${id}`;

/**
 * Function to cast a TYPO3 configuration value to a boolean.
 * @param value
 * @returns boolean
 */
export function castToBoolean(value: unknown): boolean {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return value === 'true' || value === '1';
  }
  return Boolean(value);
}

export type DynamicTextContext = Record<string, unknown>;

/**
 * Replaces dynamic text in a string with the given context.
 * @example replaceDynamicText('Hello {{name || World}}', { name: 'John' }) // Hello John
 * @param source - The source string
 * @param context - The context object
 */
export function replaceDynamicText(
  source = '',
  context: DynamicTextContext = {},
): string {
  return source.replace(
    /\{\{\s?([\w.]+)\s?(?:\|\|\s?([^}]+)\s?)?\}\}/g,
    (match, key, fallback = '') => {
      const value = key
        .trim()
        .split('.')
        .reduce((obj, index) => obj?.[index], context);
      return value ? String(value).trim() : fallback.trim();
    },
  );
}

/**
 * Replaces dynamic texts recursively in an object with the given context.
 * @param source - The source object
 * @param context - The context object
 * @param attributes - The attributes to deeply check for dynamic texts
 */
export function replaceDynamicTexts<T>(
  source: T,
  context: DynamicTextContext = {},
  attributes: string[] = ['headline', 'text', 'items', 'list'],
): T {
  if (typeof source === 'string') {
    return replaceDynamicText(source, context) as unknown as T;
  } else if (Array.isArray(source)) {
    return source.map((item) =>
      replaceDynamicTexts(item, context),
    ) as unknown as T;
  } else if (typeof source === 'object') {
    const result = {} as T;
    Object.keys(source).forEach((key) => {
      result[key] = attributes.includes(key)
        ? replaceDynamicTexts(source[key], context)
        : source[key];
    });
    return result;
  }

  return source;
}

export const validateChipsetId = (id: string): boolean =>
  id.length === 11 && id.startsWith('00') && /^\d+$/.test(id);
