import { useEffect, useMemo, useState } from 'react';
import { Promotions } from '../components/AvailabilityCheck';
import { FormFieldValues as AvailabilityFormFields } from '../components/AvailabilityCheck/Views/Form';
import { useOrderContext } from '../components/OrderContext';
import { checkAvailability } from '../utils/orderAPI';
import {
  createAvailabilityList,
  fiberTechnologies,
  getMaxDownloads,
  getTechnologyDownloads,
  hasTechnologies,
} from '../utils/promotion';
import { PLANNED_FTTH_STATUS } from '../constants';
import { Portfolio } from '../components/AvailabilityCheck/Context';
import {
  AvailabilityStatus,
  FetchAddressReponse,
  LandlinePromotion,
  ObjectInformation,
  Technology,
} from '@ncs-frontend-monorepo/availability';

export interface AvailabilityResponse {
  promotions: Promotions;
  address: FetchAddressReponse;
  objectInformation: ObjectInformation;
  isFTTHPlanned: boolean;
  isChecked: boolean;
}

export interface AvailabilityLoggingInfo {
  manualChecked: boolean;
  requestedDownload?: number;
}

const initialPromotions: Promotions = {
  availablePromotions: [],
  presalePromotion: null,
  tvPromotions: null,
  hasFallback: null,
};

const initialAvailabilityResponse: AvailabilityResponse = {
  promotions: initialPromotions,
  address: null,
  objectInformation: null,
  isFTTHPlanned: false,
  isChecked: false,
};

export const useAvailability = () => {
  const {
    setAvailability,
    setFTTHPresalesInformation,
    setInkassoTypeValidFrom,
  } = useOrderContext();
  const [availabilityInfo, setAvailabilityInfo] =
    useState<AvailabilityResponse>(initialAvailabilityResponse);
  const [noAvailability, setNoAvailability] = useState(false);

  const handleAvailabilityCheck = async (
    addressFields: AvailabilityFormFields,
    loggingInfo?: AvailabilityLoggingInfo,
    ftthCheck = false,
  ) => {
    const { zipCode, street, houseNumber, city } = addressFields;
    const {
      availablePromotions = [],
      presalePromotion,
      tvPromotions,
      address,
      hasFallback,
      objectInformation = null,
    } = await checkAvailability({
      zipCode,
      street,
      houseNumber,
      ...(city && { city }),
      ...(loggingInfo && { loggingInfo }),
    });

    const isFTTHPlanned = checkIfFTTHIsPlanned(
      objectInformation,
      availablePromotions,
      presalePromotion,
    );

    if (availablePromotions || presalePromotion) {
      setAvailabilityInfo({
        promotions: {
          availablePromotions,
          presalePromotion,
          tvPromotions,
          hasFallback,
        },
        address,
        objectInformation,
        isFTTHPlanned,
        isChecked: true,
      });
      setNoAvailability(false);
    } else if (isFTTHPlanned || ftthCheck) {
      setAvailabilityInfo({
        ...initialAvailabilityResponse,
        objectInformation,
        isFTTHPlanned,
        address,
        isChecked: true,
      });
      setNoAvailability(false);
    } else {
      setAvailability(0);
      setNoAvailability(true);
    }

    setFTTHPresalesInformation(
      objectInformation,
      getAlternativeProductAvailability([
        ...(availablePromotions ? availablePromotions : []),
        ...(presalePromotion ? [presalePromotion] : []),
      ]),
    );

    setInkassoTypeValidFrom(objectInformation?.inkassoTypeValidFrom);

    return address;
  };

  const checkIfFTTHIsPlanned = (
    objectInformation: ObjectInformation,
    availablePromotions: LandlinePromotion[],
    presalePromotion: LandlinePromotion,
  ) => {
    if (
      hasTechnologies({
        technologies: [
          Technology.GFAST,
          Technology.FTTH,
          Technology.FTTH_BSA_L2,
          Technology.FTTH_BSA_L2_DG,
        ],
        availablePromotions,
        presalePromotion,
      })
    ) {
      return false;
    }
    return (
      objectInformation?.status &&
      PLANNED_FTTH_STATUS.includes(objectInformation.status)
    );
  };

  const initializeAvailability = () => {
    setAvailabilityInfo(initialAvailabilityResponse);
    setNoAvailability(false);
    setInkassoTypeValidFrom(null);
  };

  const getAlternativeProductAvailability = (
    promotions: LandlinePromotion[],
  ) => {
    const allowedAvailabilities = [
      AvailabilityStatus.AVAILABLE,
      AvailabilityStatus.AVAILABLE_INSTALL_GFAST,
      AvailabilityStatus.PRESALE,
      AvailabilityStatus.REALIZABLE,
      AvailabilityStatus.REALIZABLE_INSTALL_GFAST,
    ];

    const promotion = promotions.find(
      (promotion) =>
        allowedAvailabilities.some(
          (allowed) => allowed === promotion.availability,
        ) && fiberTechnologies.includes(promotion.technology),
    );

    return promotion?.availability || AvailabilityStatus.NOT_AVAILABLE;
  };

  const { availabilityList, availablePortfolio } = useMemo<{
    availabilityList: string[];
    availablePortfolio: Portfolio;
  }>(() => {
    const { availablePromotions, presalePromotion, hasFallback } =
      availabilityInfo.promotions;

    const availabilityList = createAvailabilityList({
      availablePromotions,
      objectInformation: availabilityInfo.objectInformation,
      presalePromotion,
      hasFallback,
    });

    return {
      availabilityList,
      availablePortfolio: availabilityList.includes('CLASSIC')
        ? 'CLASSIC'
        : 'FIBER',
    };
  }, [availabilityInfo]);

  useEffect(() => {
    if (!availabilityInfo?.address) {
      initializeAvailability();
      return;
    }
    const { availablePromotions, presalePromotion, tvPromotions, hasFallback } =
      availabilityInfo.promotions;
    const isFTTHPlanned = availabilityInfo.isFTTHPlanned;

    if (availablePromotions?.length > 0 || presalePromotion || isFTTHPlanned) {
      const { maxAvailableDownload, plannedAvailability } = getMaxDownloads({
        availablePromotions,
        presalePromotion,
      });

      const {
        zipCode,
        street,
        houseNumber,
        city,
        hausLfdnr,
        mandant,
        district,
      } = availabilityInfo.address;

      setAvailability(
        maxAvailableDownload,
        plannedAvailability,
        tvPromotions,
        getTechnologyDownloads(availablePromotions, presalePromotion),
        {
          zipCode,
          street,
          houseNumber,
          city,
          houseSeqNumber: hausLfdnr,
          mandant,
          district,
        },
        hasFallback,
      );
    } else {
      initializeAvailability();
    }
  }, [availabilityInfo]);

  return {
    noAvailability,
    availabilityInfo,
    availabilityList,
    availablePortfolio,
    handleAvailabilityCheck,
    initializeAvailability,
    getAlternativeProductAvailability,
  };
};
