import React from 'react';
import { ErrorMessage, Grid, LoadingSpinner, Spacer } from 'dss-ui-library';
import { useCheckout } from './useCheckout';
import {
  CheckoutStepId,
  CheckoutSteps,
  TemplateGroup,
  Product,
} from '@ncs-frontend-monorepo/order';
import { Summary } from './Elements/Summary';
import { TV_GROUPS } from '../../constants/tv';
import { ActionButtons } from './Elements/ActionButtons';
import dynamic from 'next/dynamic';
import { ButtonLink } from '@ncs-frontend-monorepo/content-library';
import { KUNDENWELT_ACTIONCODE_URL } from '../../constants/urls';
import { useOrderContext } from '../OrderContext';

const IadDevice = dynamic(() =>
  import('./Elements/IadDevice').then(({ IadDevice }) => IadDevice),
);
const Service = dynamic(() =>
  import('./Elements/Service').then(({ Service }) => Service),
);
const Preselect = dynamic(() =>
  import('./Elements/Preselect').then(({ Preselect }) => Preselect),
);
const TVDevice = dynamic(() =>
  import('./Elements/TVDevice').then(({ TVDevice }) => TVDevice),
);
const NetTVDevice = dynamic(() =>
  import('./Elements/NetTVDevice').then(({ NetTVDevice }) => NetTVDevice),
);
const StepContent = dynamic(() =>
  import('./Elements/StepContent').then(({ StepContent }) => StepContent),
);
const OntDevice = dynamic(() =>
  import('./Elements/OntDevice').then(({ OntDevice }) => OntDevice),
);

export const defaultCheckoutSteps: CheckoutSteps[] = [
  {
    id: CheckoutStepId.IadDevice,
    title: 'WLAN-Router',
    headline: 'Wähle einen passenden WLAN-Router',
    buttonText: 'Weiter zum WLAN-Router',
  },
  {
    id: CheckoutStepId.OntDevice,
    title: 'Glasfaser-Modem',
    headline: 'Wähle dein Glasfaser-Modem',
    buttonText: 'Weiter zum Glasfaser-Modem',
  },
  {
    id: CheckoutStepId.TvDevice,
    title: 'Empfangsgerät',
    headline: 'Wähle ein passendes Empfangsgerät',
    buttonText: 'Weiter zum Empfangsgerät',
  },
  {
    id: CheckoutStepId.NetTvDevice,
    title: 'NetTV-Box',
    headline: 'Wähle deine TV-Hardware',
    buttonText: 'Weiter zur NetTV-Box',
  },
  {
    id: CheckoutStepId.Service,
    title: 'NetStart',
    headline: 'Möchtest du einen Service dazu buchen?',
    buttonText: 'Weiter zu NetStart',
  },
];

export const renderCheckoutStep = (
  checkoutStepId: CheckoutStepId,
  availableProduct: Product,
) => {
  switch (checkoutStepId) {
    case CheckoutStepId.IadDevice:
      return <IadDevice availableProduct={availableProduct} />;
    case CheckoutStepId.OntDevice:
      return <OntDevice availableProduct={availableProduct} />;
    case CheckoutStepId.TvDevice:
      return <TVDevice availableProduct={availableProduct} />;
    case CheckoutStepId.NetTvDevice:
      return <NetTVDevice availableProduct={availableProduct} />;
    case CheckoutStepId.Service:
      return <Service availableProduct={availableProduct} />;
  }
};

interface CheckoutProps {
  templateGroup: TemplateGroup;
  checkoutActionCode?: string;
  resetAddress: () => void;
}

export enum CheckoutViews {
  Preselect = 'preselect',
  Steps = 'steps',
  SingleCheckout = 'singleCheckout',
  Checkout = 'checkout',
  Summary = 'summary',
}

export const Checkout: React.FC<CheckoutProps> = ({
  templateGroup,
  checkoutActionCode,
  resetAddress,
}) => {
  const isTVProduct = TV_GROUPS.includes(templateGroup);
  const {
    availableProduct,
    checkoutSteps,
    currentStep,
    goToStep,
    viewType,
    handlePrev,
    handleNext,
    resetSelection,
    errorText,
    handleSubmit,
  } = useCheckout({
    isTVProduct,
  });
  const { formData } = useOrderContext();

  const handleResetAddress = () => {
    resetAddress();
    resetSelection(false);
  };

  const isSubmit =
    viewType === CheckoutViews.Checkout ||
    viewType === CheckoutViews.Preselect ||
    viewType === CheckoutViews.SingleCheckout ||
    checkoutSteps?.length === currentStep + 1;

  if (!availableProduct || !viewType) {
    return <LoadingSpinner theme="blue" />;
  }

  return (
    <div>
      {viewType !== CheckoutViews.Steps && (
        <Summary
          availableProduct={availableProduct}
          onResetAddress={handleResetAddress}
          isTVProduct={isTVProduct}
          hasPreselect={viewType === CheckoutViews.Preselect}
          onResetPreselect={resetSelection}
        />
      )}
      {viewType === CheckoutViews.Preselect && (
        <Preselect
          checkoutSteps={checkoutSteps}
          availableProduct={availableProduct}
        />
      )}
      {viewType === CheckoutViews.SingleCheckout && (
        <>{renderCheckoutStep(checkoutSteps[0].id, availableProduct)}</>
      )}
      {viewType === CheckoutViews.Steps && (
        <StepContent
          checkoutSteps={checkoutSteps}
          availableProduct={availableProduct}
          currentStep={currentStep}
          goToStep={goToStep}
          renderCheckoutStep={renderCheckoutStep}
        />
      )}
      <>
        {errorText && (
          <Spacer t={1} b={2} block>
            <ErrorMessage e2e="router-not-selected">{errorText}</ErrorMessage>
          </Spacer>
        )}
        {checkoutActionCode && formData?.isCustomer ? (
          <Grid fullWidth>
            <Grid.Row>
              <Grid.Column hEndS vCenter>
                <ButtonLink
                  link={{
                    url: KUNDENWELT_ACTIONCODE_URL + checkoutActionCode,
                    e2e: 'kundenwelt-actioncode',
                  }}
                  btnColor="red"
                >
                  Jetzt einloggen
                </ButtonLink>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <ActionButtons
            handleNext={isSubmit ? handleSubmit : handleNext}
            handlePrev={handlePrev}
            withPrev={viewType === CheckoutViews.Steps}
            e2e={
              isSubmit
                ? 'checkout'
                : checkoutSteps?.[
                    viewType === CheckoutViews.Summary ? 0 : currentStep + 1
                  ]?.id
            }
            nextButtonText={
              isSubmit
                ? 'Zur Kasse'
                : checkoutSteps?.[
                    viewType === CheckoutViews.Summary ? 0 : currentStep + 1
                  ]?.buttonText
            }
          />
        )}
      </>
    </div>
  );
};
