import { LoadingSpinner } from 'dss-ui-library';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { TV_GROUPS } from '../../constants/tv';
import { useAvailability } from '../../hooks/useAvailability';
import { AvailabilityStatus, DataLayerEvents } from '../../interfaces/tracking';
import { pushAvailability } from '../../utils/dataLayer/availability';
import { isFiberPlan } from '../../utils/plan';
import {
  getMaxDownloads,
  getPromotionWithMaxDownload,
  hasCableOptions,
  hasCableTV,
} from '../../utils/promotion';
import { useOrderContext } from '../OrderContext';
import { AvailabilityProvider, AvailabilityTypes, Portfolio } from './Context';
import { FormFieldValues } from './Views/Form';
import { Checkout } from '../Checkout';
import { NetTvRedirect } from './Views/NetTvRedirect';
import {
  ProductGroups,
  ProductId,
  TemplateGroup,
} from '@ncs-frontend-monorepo/order';
import { checkMandantType, getEnv } from '@ncs-frontend-monorepo/utils';
import {
  AvailabilityResponse,
  Fallback,
  LandlinePromotion,
  MandantMismatch,
} from '@ncs-frontend-monorepo/availability';

const AvailabilityForm = dynamic(() =>
  import('./Views/Form').then(({ AvailabilityForm }) => AvailabilityForm),
);
const AvailabilityOptionSelect = dynamic(() =>
  import('./Views/OptionSelect').then(
    ({ AvailabilityOptionSelect }) => AvailabilityOptionSelect,
  ),
);
const AvailabilityPresale = dynamic(() =>
  import('./Views/Presale').then(
    ({ AvailabilityPresale }) => AvailabilityPresale,
  ),
);
const NetTvFallback = dynamic(() =>
  import('./Views/NetTvFallback').then(({ NetTvFallback }) => NetTvFallback),
);
const ADSLFallback = dynamic(() =>
  import('./Views/ADSLFallback').then(({ ADSLFallback }) => ADSLFallback),
);
const PortfolioMismatchFallback = dynamic(() =>
  import('./Views/PortfolioMismatchFallback').then(
    ({ PortfolioMismatchFallback }) => PortfolioMismatchFallback,
  ),
);
const NetTvCombi = dynamic(() =>
  import('./Views/NetTvCombi').then(({ NetTvCombi }) => NetTvCombi),
);
const FTTHPlannedFallback = dynamic(() =>
  import('./Views/FTTHPlannedFallback').then(
    ({ FTTHPlannedFallback }) => FTTHPlannedFallback,
  ),
);
const AvailabilityError = dynamic(() =>
  import('./Views/Error').then(({ AvailabilityError }) => AvailabilityError),
);

export interface AvailabilityProps {
  desiredEntrypoint?:
    | ViewType.CHECKOUT
    | ViewType.OPTION_SELECT
    | ViewType.LOADING;
  templateId?: ProductId;
  checkoutActionCode?: string;
  templateGroup?: TemplateGroup;
  withoutRuntime?: boolean;
  requestedDownload?: number;
  requestedPlanName?: string;
  availabilityType?: AvailabilityTypes;
  portfolio?: Portfolio;
  groups?: ProductGroups[];
  kundenweltURL?: string;
}

export enum LayoutType {
  Modal = 'modal',
  EmbeddedTv = 'embeddedTv',
  EmbeddedTvStandalone = 'embeddedTvStandalone',
  EmbeddedNetSpeed = 'embeddedNetSpeed',
  Small = 'small',
}

export enum ViewType {
  LOADING = 'loading',
  PRESALE = 'presale',
  FORM = 'form',
  OPTION_SELECT = 'option-select',
  ERROR = 'error',
  MISMATCH = 'mismatch',
  CHECKOUT = 'checkout',
  CHECKOUT_TV = 'checkout-tv',
  FTTH_PLANNED_FALLBACK = 'ftth-planned-fallback',
  NETTV_FALLBACK = 'nettv-fallback',
  NETTV_REDIRECT = 'nettv-redirect',
  NETTV_COMBI = 'nettv-combi',
  ADSL_FALLBACK = 'adsl-fallback',
  PORTFOLIO_MISMATCH = 'portfolio-mismatch',
}

export interface Promotions {
  availablePromotions: LandlinePromotion[];
  presalePromotion: LandlinePromotion;
  tvPromotions: AvailabilityResponse['tvPromotions'];
  hasFallback: Fallback | null;
}

export const Availability: React.FC<AvailabilityProps> = ({
  templateId,
  checkoutActionCode,
  templateGroup,
  withoutRuntime = false,
  requestedDownload,
  requestedPlanName,
  desiredEntrypoint = ViewType.LOADING,
  availabilityType = AvailabilityTypes.Global,
  portfolio,
  groups = [],
  kundenweltURL,
}) => {
  const {
    clearOrder,
    address,
    maxAvailableDownload,
    plannedAvailability,
    setAvailability,
  } = useOrderContext();
  const {
    noAvailability,
    availabilityInfo,
    availablePortfolio,
    initializeAvailability,
    handleAvailabilityCheck,
  } = useAvailability();

  const {
    eventOpen,
    eventClose,
    eventCheck,
    eventChangeAddress,
    getAvailabilityStatus,
    eventLinks,
  } = pushAvailability();

  const availabilityStatus = availabilityInfo?.promotions.presalePromotion
    ? maxAvailableDownload > 0
      ? AvailabilityStatus.AvailablePresale
      : AvailabilityStatus.PresaleOnly
    : AvailabilityStatus.Available;

  const [requiredPromotion, setRequiredPromotion] =
    useState<LandlinePromotion>();
  const [manualChecked, setManualChecked] = useState(false);

  // If address is given, directly check, otherwise show form
  const [view, setView] = useState<ViewType>(
    address !== null ? ViewType.LOADING : ViewType.FORM,
  );

  const resetCheck = () => {
    if (address) {
      eventChangeAddress({
        event: DataLayerEvents.ChangeAddress,
        status: getAvailabilityStatus(availabilityInfo, requestedDownload),
        checkSource: availabilityType,
        zipCode: address.zipCode,
        maxDownload: maxAvailableDownload,
        expansionStatus: availabilityInfo?.objectInformation?.status,
      });
    }
    clearOrder();
    initializeAvailability();
    setRequiredPromotion(null);
    setView(ViewType.FORM);
  };

  const handleCheck = async (address: FormFieldValues, manualCheck = false) => {
    setManualChecked(manualCheck);
    await handleAvailabilityCheck(
      address,
      manualCheck && {
        manualChecked: true,
        ...(requestedDownload && { requestedDownload }),
      },
    );

    manualCheck &&
      eventCheck({
        event: DataLayerEvents.Check,
        checkSource: availabilityType,
        zipCode: address.zipCode,
      });
  };

  // Initial check with saved address
  useEffect(() => {
    if (view === ViewType.LOADING) {
      handleCheck(address);
    }

    const availabilityChecked = !!(
      address &&
      (maxAvailableDownload || plannedAvailability)
    );

    eventOpen({
      event: DataLayerEvents.Open,
      checkStatus: availabilityChecked ? 'bereits-geprueft' : 'nicht-geprueft',
      checkSource: availabilityType,
      ...(requestedDownload && { requestedDownload }),
      ...(templateId && { templateId }),
    });

    return () => {
      eventClose({
        event: DataLayerEvents.Close,
        checkSource: availabilityType,
      });
    };
  }, []);

  // Reset view if template or runtime change
  useEffect(() => {
    if (view !== ViewType.LOADING) {
      resetCheck();
    }
  }, [templateId, withoutRuntime]);

  const handleTvSelection = () => {
    const {
      promotions: { tvPromotions },
    } = availabilityInfo;

    if (tvPromotions?.length === 0) {
      setView(ViewType.ERROR);
      return;
    }

    switch (true) {
      case templateGroup === TemplateGroup.TV_INTERNET:
        return setView(ViewType.NETTV_REDIRECT);
      case groups.includes(ProductGroups.TV_OPTION):
        return hasCableOptions(tvPromotions)
          ? setView(ViewType.CHECKOUT_TV)
          : setView(ViewType.NETTV_FALLBACK);
      case hasCableTV(tvPromotions):
        return setView(ViewType.CHECKOUT_TV);
      default:
        return setView(ViewType.NETTV_FALLBACK);
    }
  };

  const handleNetSpeedSelection = () => {
    const {
      promotions: {
        availablePromotions,
        presalePromotion,
        tvPromotions,
        hasFallback,
      },
      isFTTHPlanned,
    } = availabilityInfo;

    const { maxAvailableDownload } = getMaxDownloads({
      availablePromotions,
      presalePromotion,
    });

    if (availablePromotions.length > 0 || presalePromotion) {
      if (hasFallback === Fallback.ADSL && !presalePromotion) {
        setView(ViewType.ADSL_FALLBACK);
        return;
      }

      // availability check with GFAST: show presale
      if (
        desiredEntrypoint != ViewType.OPTION_SELECT &&
        requestedDownload > maxAvailableDownload &&
        presalePromotion?.maxDownload >= requestedDownload
      ) {
        setView(ViewType.PRESALE);
        return;
      }

      // availability check without template: show option
      if (desiredEntrypoint === ViewType.OPTION_SELECT) {
        setView(ViewType.OPTION_SELECT);
        return;
      }

      // no promotions available: show error
      if (availablePromotions.length === 0 && !presalePromotion) {
        setView(ViewType.ERROR);
        return;
      }

      // on cable-tv product tvPromotions has to be checked
      if (
        [
          TemplateGroup.KOMBI_TV_CABLE,
          TemplateGroup.KOMBI_PHONE_TV_CABLE,
        ].includes(templateGroup) &&
        !hasCableTV(tvPromotions)
      ) {
        setView(ViewType.NETTV_FALLBACK);
        return;
      }

      // If a promotion is available, always try to check out directly
      if (availablePromotions.length > 0) {
        // check if maximum available download fits requested
        if (maxAvailableDownload < requestedDownload) {
          if (presalePromotion) {
            if (
              desiredEntrypoint === ViewType.CHECKOUT &&
              presalePromotion.maxDownload >= requestedDownload
            ) {
              setRequiredPromotion(presalePromotion);
              setView(ViewType.CHECKOUT);
            } else {
              setView(ViewType.PRESALE);
            }
          } else {
            // set promotion with maximum download to get best fallback
            setRequiredPromotion(
              getPromotionWithMaxDownload(availablePromotions),
            );
            setView(ViewType.CHECKOUT);
          }
        } else {
          const portfolioMatches = availablePromotions
            .filter((promotion) => promotion.maxDownload >= requestedDownload)
            .map((promotion) =>
              isFiberPlan(promotion.technology) ? 'FIBER' : 'CLASSIC',
            )
            .includes(portfolio);

          // if a portfolio is given but does not match with the available portfolio
          if (portfolio && !portfolioMatches) {
            setView(ViewType.PORTFOLIO_MISMATCH);
            return;
          }

          const requiredPromotion = availablePromotions.filter(
            (promotion) =>
              promotion.minDownload <= requestedDownload &&
              promotion.maxDownload >= requestedDownload,
          )[0];

          if (requiredPromotion) {
            setRequiredPromotion(requiredPromotion);
            setView(ViewType.CHECKOUT);
          } else {
            setView(ViewType.ERROR);
          }
        }
        return;
      }

      //nothing matched: show error
      setView(ViewType.ERROR);
    } else {
      if (isFTTHPlanned) {
        setView(ViewType.FTTH_PLANNED_FALLBACK);
      }
    }
  };

  useEffect(() => {
    if (noAvailability) {
      setAvailability(0);
      setView(ViewType.ERROR);
    }
  }, [noAvailability]);

  const mandant = availabilityInfo.address?.['mandant'];
  const mandantType = checkMandantType(getEnv().SITE, mandant);

  useEffect(() => {
    if (mandantType === null && typeof mandant !== 'undefined') {
      setView(ViewType.ERROR);
      return;
    }
    if (mandantType !== 'match' && mandant) {
      setView(ViewType.MISMATCH);
      return;
    }

    if (availabilityInfo.isChecked && !noAvailability) {
      TV_GROUPS.includes(templateGroup)
        ? handleTvSelection()
        : handleNetSpeedSelection();
    }
  }, [availabilityInfo, noAvailability, mandantType]);

  return (
    <AvailabilityProvider
      availabilityType={availabilityType}
      requiredPromotion={requiredPromotion}
      requestedDownload={requestedDownload}
      templateId={templateId}
      withoutRuntime={withoutRuntime}
      availabilityInfo={availabilityInfo}
      manualCheck={manualChecked}
      availablePortfolio={availablePortfolio}
      kundenweltURL={kundenweltURL}
    >
      <>
        {view === ViewType.LOADING && <LoadingSpinner theme="blue" />}
        {view === ViewType.FORM && <AvailabilityForm onSubmit={handleCheck} />}
        {view === ViewType.MISMATCH && (
          <MandantMismatch
            address={address}
            resetAddress={resetCheck}
            mandantType={mandantType}
            onRedirect={({ targetUrl }) => {
              eventLinks({
                event: DataLayerEvents.MandantMismatchLink,
                status: availabilityStatus,
                zipCode: address.zipCode,
                checkSource: availabilityType,
                maxDownload: maxAvailableDownload,
                targetPage: targetUrl,
              });
            }}
            fallback={
              <AvailabilityError
                address={address}
                onChangeAddressClick={resetCheck}
              />
            }
          />
        )}
        {view === ViewType.OPTION_SELECT && (
          <AvailabilityOptionSelect onChangeAddressClick={resetCheck} />
        )}
        {view === ViewType.PORTFOLIO_MISMATCH && (
          <PortfolioMismatchFallback
            onPromotionSelect={(promotion) => {
              setRequiredPromotion(promotion);
              setView(ViewType.CHECKOUT);
            }}
            requestedPlanName={requestedPlanName}
            onChangeAddressClick={resetCheck}
            currentPortfolio={portfolio}
          />
        )}
        {view === ViewType.PRESALE && availabilityInfo.promotions && (
          <AvailabilityPresale
            portfolio={portfolio}
            onChangeAddressClick={resetCheck}
            onPromotionSelect={(promotion) => {
              setRequiredPromotion(promotion);
              setView(ViewType.CHECKOUT);
            }}
          />
        )}
        {view === ViewType.NETTV_FALLBACK && (
          <NetTvFallback resetAddress={resetCheck} />
        )}
        {view === ViewType.ADSL_FALLBACK && (
          <ADSLFallback resetAddress={resetCheck} />
        )}
        {view === ViewType.CHECKOUT && requiredPromotion && (
          <Checkout templateGroup={templateGroup} resetAddress={resetCheck} />
        )}
        {view === ViewType.CHECKOUT_TV && (
          <Checkout
            templateGroup={templateGroup}
            resetAddress={resetCheck}
            checkoutActionCode={checkoutActionCode}
          />
        )}
        {view === ViewType.NETTV_COMBI && (
          <NetTvCombi resetAddress={resetCheck} />
        )}
        {view === ViewType.NETTV_REDIRECT && (
          <NetTvRedirect resetAddress={resetCheck} />
        )}
        {view === ViewType.FTTH_PLANNED_FALLBACK && (
          <FTTHPlannedFallback
            hasPreselectedProduct={!!templateId}
            address={address}
            onChangeAddressClick={resetCheck}
          />
        )}
        {view === ViewType.ERROR && (
          <AvailabilityError
            address={address}
            onChangeAddressClick={resetCheck}
          />
        )}
      </>
    </AvailabilityProvider>
  );
};
